import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';

// Stylesheet
import st from './leftBottom.module.scss';
import cns from 'classnames';
import s from './components.module.scss';
import cls from './certification.module.scss';
import p from './education.module.scss';
import certIcon from '../../../../../assets/icons/Certifications.svg';
import { Button, Modal, Box, Tooltip } from '@material-ui/core';

//Helper
import { uniqueId } from 'lodash';
import { Skeleton } from '@material-ui/lab';
import educationLogo from '../../../../../assets/icons/Edcucation.svg';
import projectIcon from '../../../../../assets/icons/ProjectIcon.svg';

import _ from 'lodash';
import { useMediaQuery } from 'react-responsive';

const LeftBottomShare = ({
  aboutMe,
  userAddedSkills,
  achievements,
  trainingsDone,
  projects,
  certificates,
  educationDetails
}) => {
  // const { isMobile } = Viewport();
  const isMobile = useMediaQuery({ maxWidth: 992 });
  let wrapper = useRef();
  const achievement = achievements ? achievements : '';
  const training = trainingsDone ? trainingsDone : '';
  const project = projects ? projects : '';
  const about = aboutMe ? aboutMe : '';
  const certificate = certificates ? certificates : '';
  const educationDetail = educationDetails ? educationDetails : '';
  const skillInput = userAddedSkills ?? '';
  const [activeCerti, setActiveCerti] = useState(-1);
  const [loading, setLoading] = useState(false);
  const [expertSkill, setExpertSkill] = useState([]);
  const [intermediateSkill, setIntermediateSkill] = useState([]);
  const [beginnerSkill, setBeginnerSkill] = useState([]);

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    p: 4
  };

  useEffect(() => {
    if (about || skillInput || achievement || training || project || certificate) {
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [skillInput, achievement, training, project, certificate, about]);

  useEffect(() => {
    const skillsArray = skillInput?.split(', ');
    const categorizeSkills = (proficiency) => {
      if (skillsArray) {
        // Regex to match skills based on proficiency
        const proficiencyRegex = new RegExp(`\\(${proficiency}(?:/([^()]+))?\\)`);
        return skillsArray
          .map((skill) => {
            // Convert skill (proficiency/type) -> skill (type)
            return skill.replace(/(\([^)]+)\/([^()]+)\)/, '($2)');
          })
          .filter((skill) => proficiencyRegex.test(skill))
          .map((skill) => skill.replace(proficiencyRegex, '').trim());
      }
      return [];
    };

    setExpertSkill(categorizeSkills('Expert'));
    setIntermediateSkill(categorizeSkills('Intermediate'));
    setBeginnerSkill(categorizeSkills('Beginner'));
  }, [skillInput]);

  const renderSkills = (skills, prof) => {
    return skills.map((skill, idx) => {
      const isEmpty = /^ *$/.test(skill);

      const typeRegex = /\((Technical|Functional|Behaviour)\)/;
      const match = skill.match(typeRegex);

      const type = match ? match[1] : ''; // If a match is found, extract the type (without parentheses); otherwise, set type as an empty string
      const skillName = skill.replace(typeRegex, '').trim();

      return (
        <React.Fragment key={idx}>
          {!isEmpty && (
            <div
              className={s.displaySkillDesc}
              style={{
                backgroundColor:
                  type === 'Technical'
                    ? '#d1f7c4'
                    : type === 'Functional'
                    ? '#eaf2f4'
                    : type === 'Behaviour'
                    ? '#fdd8e6'
                    : 'transparent' // Default color
              }}
            >
              {skillName ? (
                <p>{skillName}</p>
              ) : (
                <Skeleton variant="text" className={s.textLoader} />
              )}
            </div>
          )}
        </React.Fragment>
      );
    });
  };

  return (
    <>
      {about.length > 0 && (
        <div className={isMobile ? st.mobileLeftBottom : st.desktopLeftBottom}>
          <div className={isMobile ? st.leftBottom : st.leftBottomInner}>
            <div className={cns(s.skillsWrapper, isMobile ? s.skillResponsive : '')} ref={wrapper}>
              <div className={s.heading}>
                <h5>About</h5>
              </div>
              <div className={s.description}>
                {loading ? (
                  <>
                    <Skeleton variant="text" className={s.textLoader} />
                    <Skeleton variant="text" className={s.textLoader} />
                    <Skeleton variant="text" className={s.textLoader} />
                    <Skeleton variant="text" className={s.textLoader} />
                  </>
                ) : about?.length ? (
                  <React.Fragment>
                    {
                      <div>
                        {about ? (
                          <p dangerouslySetInnerHTML={{ __html: about.replace(/\n/g, '<br>') }} />
                        ) : (
                          <Skeleton variant="text" className={s.textLoader} />
                        )}
                      </div>
                    }
                  </React.Fragment>
                ) : (
                  <i className="text-secondary">No information available</i>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      <div className={isMobile ? st.mobileLeftBottom : st.desktopLeftBottom}>
        <div className={isMobile ? st.leftBottom : st.leftBottomInner}>
          <div className={cns(s.skillsWrapper, isMobile ? s.skillResponsive : '')} ref={wrapper}>
            <div className={cns(s.skillsWrapper, isMobile ? s.skillResponsive : '')}>
              <div className={s.heading}>
                <h5>Skills</h5>
                <div className={s.editIcon}></div>
              </div>
              <div className={s.skillDescription}>
                {loading ? (
                  <>
                    <Skeleton variant="text" className={s.textLoader} />
                    <Skeleton variant="text" className={s.textLoader} />
                    <Skeleton variant="text" className={s.textLoader} />
                    <Skeleton variant="text" className={s.textLoader} />
                  </>
                ) : skillInput?.length ? (
                  <>
                    {expertSkill.length > 0 && (
                      <div className={s.skillDiv}>
                        <div className={s.skillType}>Expert: </div>
                        <div className={s.skillNames}> {renderSkills(expertSkill, 'expert')}</div>
                      </div>
                    )}

                    {intermediateSkill.length > 0 && (
                      <div className={s.skillDiv}>
                        <div className={s.skillType}>Intermediate: </div>
                        <div className={s.skillNames}>
                          {renderSkills(intermediateSkill, 'intermediate')}
                        </div>
                      </div>
                    )}

                    {beginnerSkill.length > 0 && (
                      <div className={s.skillDiv}>
                        <div className={s.skillType}>Beginner: </div>
                        <div className={s.skillNames}>
                          {renderSkills(beginnerSkill, 'beginner')}
                        </div>
                      </div>
                    )}
                  </>
                ) : (
                  <i className="text-secondary">No Skills added.</i>
                )}
              </div>
            </div>
          </div>
          {/* ------------------------Education------------------------------------ */}
          <div>
            <div className={s.heading}>
              <h5>Education</h5>
            </div>
            <div className={p.detailsContainer}>
              {loading ? (
                <div className={p.containerLoader}>
                  <div>
                    <Skeleton variant="circle" className={p.circleLoader} />
                  </div>
                  <div className={p.detailsLoadingContainer}>
                    <Skeleton variant="text" className={p.textLoad} />
                    <Skeleton variant="text" className={p.textLoad} />
                    <Skeleton variant="text" className={p.textLoad} />
                  </div>
                </div>
              ) : educationDetail.length ? (
                educationDetail.map((data, index) => {
                  return (
                    <div key={index} className={p.detailsWrapper}>
                      <img src={educationLogo} className={p.logo} alt="Add-icon" />
                      <div className={p.details}>
                        <p className={p.college}>{data.college}</p>
                        <p className={p.degree}>{data.degree}</p>
                        <p className={p.year}>
                          {data.fromYear}-{data.toYear}
                        </p>
                      </div>
                    </div>
                  );
                })
              ) : (
                <div style={{ marginTop: '14px' }}>
                  <i className="text-secondary">No Education details added.</i>
                </div>
              )}
            </div>
          </div>
          {/* -------------------------------------------------------------------------------------------- */}
          {/* Certification */}
          <div>
            <div className={s.heading}>
              <h5>Certification</h5>
              <div className={s.editIcon}></div>
            </div>
            <div className={s.description}>
              {loading ? (
                <div className={s.containerLoader}>
                  <div>
                    <Skeleton variant="circle" className={s.circleLoader} />
                  </div>
                  <div className={s.detailsLoadingContainer}>
                    <Skeleton variant="text" className={s.textLoad} />
                    <Skeleton variant="text" className={s.textLoad} />
                    <Skeleton variant="text" className={s.textLoad} />
                  </div>
                </div>
              ) : certificate.length ? (
                <ul className={cls.certificates}>
                  {certificate.map((certi, idx) => {
                    certi.uniqueKey = uniqueId();
                    return (
                      <div className={cls.certiContainer} key={certi.uniqueKey}>
                        <div>
                          <img src={certIcon} alt="certificate" />
                        </div>

                        <div className={cls.certiInfoContainer} key={certi.uniqueKey}>
                          <div className={cls.certiInfo}>
                            <div className="d-flex align-items-center">
                              <Button
                                className={cls.certButton}
                                onClick={() => {
                                  setActiveCerti(idx);
                                }}
                              >
                                <Tooltip title="View Certificate">
                                  <p className={cls.certiName}>{certi.name}</p>
                                </Tooltip>
                              </Button>
                              <p className={cls?.certLevel}>
                                {_.capitalize(certi?.certificateLevel)}
                              </p>
                            </div>
                            <div className="d-flex align-items-center mt-1">
                              <p className={cls.certiProvider}>{certi.provider} </p> |
                              <p className={cns('text-secondary mx-2', cls.issueDate)}>
                                {' '}
                                Issued on {new Date(certi.issueDate).toDateString()}
                              </p>
                            </div>
                            <div className="d-flex mt-1">
                              <p className={cls.classType}>
                                {_.capitalize(certi?.classificationType)}
                              </p>
                              <p className={cls.tech}>{certi.tech.name}</p>
                            </div>
                          </div>
                          <Modal
                            open={activeCerti === idx ? true : false}
                            onClose={() => setActiveCerti(-1)}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                          >
                            <Box sx={style}>
                              {certi.certificateUrl &&
                              !certi.certificateUrl.includes('.pdf') &&
                              !(certi.certificateUrl[0] instanceof File) ? (
                                <img
                                  className="w-100 rounded-lg"
                                  alt="certificate"
                                  src={certi.certificateUrl}
                                />
                              ) : (
                                <iframe
                                  src={certi.pdf ?? certi.certificateUrl}
                                  style={{ width: '100%', height: '500px', borderRadius: '12px' }}
                                  frameBorder="0"
                                ></iframe>
                              )}
                            </Box>
                          </Modal>
                        </div>
                      </div>
                    );
                  })}
                </ul>
              ) : (
                <i className="text-secondary">No Certification added.</i>
              )}
            </div>
          </div>
          <div
            className={cns(s.achievementWrapper, isMobile ? s.achievementResponsive : '')}
            ref={wrapper}
          >
            <div className={cns(s.achievementWrapper, isMobile ? s.achievementResponsive : '')}>
              <div className={s.heading}>
                <h5>Achievement</h5>
              </div>
              <div className={s.description}>
                {loading ? (
                  <>
                    <Skeleton variant="text" className={s.textLoader} />
                    <Skeleton variant="text" className={s.textLoader} />
                    <Skeleton variant="text" className={s.textLoader} />
                    <Skeleton variant="text" className={s.textLoader} />
                  </>
                ) : achievement.length ? (
                  <>
                    {achievement.split(',').map((achievement, idx) => {
                      let isEmpty = /^ *$/.test(achievement);
                      let uniqueKey = uniqueId();
                      return (
                        <React.Fragment key={uniqueKey}>
                          {!isEmpty && (
                            <div key={idx} className={s.displayDescription}>
                              {achievement}
                            </div>
                          )}
                        </React.Fragment>
                      );
                    })}
                  </>
                ) : (
                  <i className="text-secondary">No Achievement added.</i>
                )}
              </div>
            </div>
          </div>

          {/* -------------------------------------------------------------------------------------------- */}
          {/* Trainings */}

          <div
            className={cns(s.trainingWrapper, isMobile ? s.trainingResponsive : '')}
            ref={wrapper}
          >
            <div className={s.heading}>
              <h5>Trainings</h5>
            </div>
            <div className={s.description}>
              {loading ? (
                <>
                  <Skeleton variant="text" className={s.textLoader} />
                  <Skeleton variant="text" className={s.textLoader} />
                  <Skeleton variant="text" className={s.textLoader} />
                  <Skeleton variant="text" className={s.textLoader} />
                </>
              ) : training.length ? (
                <>
                  {training.split(',').map((training, idx) => {
                    let isEmpty = /^ *$/.test(training);
                    return (
                      <React.Fragment key={idx}>
                        {!isEmpty && <div className={s.displayDescription}>{training}</div>}
                      </React.Fragment>
                    );
                  })}
                </>
              ) : (
                <i className="text-secondary">No Trainings added.</i>
              )}
            </div>
          </div>

          <div className={cns(s.certiWrapper, isMobile ? s.certiResponsive : '')}>
            <div className={s.heading}>
              <h5>Project</h5>
            </div>
            <div className={s.description}>
              {loading ? (
                <div className={s.containerLoader}>
                  <div>
                    <Skeleton variant="circle" className={s.circleLoader} />
                  </div>
                  <div className={s.detailsLoadingContainer}>
                    <Skeleton variant="text" className={s.textLoad} />
                    <Skeleton variant="text" className={s.textLoad} />
                    <Skeleton variant="text" className={s.textLoad} />
                  </div>
                  <div className={s.detailsLoadingContainer}>
                    <Skeleton variant="text" className={s.statusLoader} />
                  </div>
                </div>
              ) : project.length ? (
                <ul className={s.projectDetailsContainer}>
                  {project.map((pro) => {
                    pro.uniqueKey = uniqueId();
                    return (
                      <li key={pro.uniqueKey} className={s.projectDetails}>
                        <div className={s.projectName}>
                          <div className="d-flex ">
                            {pro.projectName ? (
                              <div className={s.providerIcon}>
                                <img src={projectIcon} alt="project-icon" />
                              </div>
                            ) : (
                              <Skeleton variant="circle" className={s.providerIcon} />
                            )}
                            <div>
                              <p className={s.proName}>
                                {pro.projectName}
                                &nbsp;&nbsp;|&nbsp;&nbsp;
                                <span className={s.projectType}>{pro.projectType}</span>
                              </p>

                              <p className={classNames(s.projectType, 'text-capitalize')}>
                                Technology : {pro.keyTech || 'NA'}
                              </p>
                              <p
                                className={s.projectDesc}
                                dangerouslySetInnerHTML={{ __html: pro.projectDescription }}
                              ></p>
                            </div>
                          </div>
                          <button
                            className={
                              pro.projectStatus === 'Completed' ? s.completebutton : s.ongoingBtn
                            }
                          >
                            {pro.projectStatus}
                          </button>
                        </div>
                      </li>
                    );
                  })}
                </ul>
              ) : (
                <i className="text-secondary">No Projects added.</i>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LeftBottomShare;
