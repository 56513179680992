import React, { useState, useRef } from 'react';
import Tooltip from '@material-ui/core/Tooltip';

// icons
import save from '../../../../../assets/icons/saveDetails.png';
import close from '../../../../../assets/icons/close.svg';
import addIcon from '../../../../../assets/icons/addIcon.svg';

// CSS
import s from './components.module.scss';
import cns from 'classnames';

//Helper
import { useDispatch, useSelector } from 'react-redux';
import { editSkill } from '../../../../../redux/actionCreators/profile/skill.actionCreator';
import { TOOLTIP_MSSG } from '../../../../../helpers';
import { Skeleton } from '@material-ui/lab';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { useMediaQuery } from 'react-responsive';
import { error } from 'jquery';

const Skill = () => {
  const dispatch = useDispatch();

  const addedSkills = useSelector(
    (state) => state.skillReducer.newSkills ?? state?.profileReducer?.details?.userAddedSkills
  );
  const loading = useSelector((state) => state?.profileReducer?.loading);
  // const { isMobile } = Viewport();
  const isMobile = useMediaQuery({ maxWidth: 992 });
  const [editFlag, setEditFlag] = useState(false);
  const [skillInput, setSkillInput] = useState(addedSkills);
  const [expertSkill, setExpertSkill] = useState([]);
  const [intermediateSkill, setIntermediateSkill] = useState([]);
  const [beginnerSkill, setBeginnerSkill] = useState([]);
  const [skillData, setSkillData] = useState({
    name: '',
    prof: '',
    type: ''
  });
  const [errors, setErrors] = useState(false);
  let wrapper = useRef();

  const handleInputChange = (e) => {
    setSkillData({ ...skillData, name: e.target.value });
  };

  const handleSave = () => {
    if (!skillData.name.trim() || !skillData.prof || !skillData.type) {
      toast.warn('Please Fill all the Fields');
      setErrors(true);
      return;
    }
    if (skillData.name.length > 40) {
      toast.warn('Skill name length exceeded!');
      setErrors(true);
      return;
    }
    if (skillData.name.includes(',')) {
      toast.error('Comma "," not allowed');
      setErrors(true);
      return;
    }

    const tempskill = skillInput
      .replace(
        /\s?\(Beginner\)|\s?\(Intermediate\)|\s?\(Expert\)|\s?\(Technical\)|\s?\(Functional\)|\s?\(Behaviour\)/g,'')
      .trim();

    if (
      tempskill
        .split(/\s*,\s*/)
        .some((skill) => skill.toLowerCase() === skillData.name.trim().toLowerCase())
    ) {
      toast.error('Entered Skill already exists!');
      return;
    }

    setSkillInput(skillInput + ', ' + skillData.name + ' (' + skillData.prof + ')' + ' (' + skillData.type + ')');

    const data = {
      skills:
        (addedSkills ? addedSkills + ', ' : '') +
        skillData.name.trim() +
        ' (' +
        skillData.prof +
        ')' +
        ' (' +
        skillData.type +
        ')'
    };
    editSkill(data)(dispatch);
    setSkillData({
      name: '',
      prof: '',
      type: ''
    });
    setErrors(false);
  };

  const handleClose = () => {
    setSkillData({
      name: '',
      prof: '',
      type: ''
    });
    setErrors(false);
    setSkillInput(addedSkills);
    setEditFlag(!editFlag);
  };

  useEffect(() => {
    setSkillInput(addedSkills);
    let newSkillInput = addedSkills;
    const skillsArray = newSkillInput?.split(', ');

    const categorizeSkills = (proficiency) => {
      if (skillsArray) {
        
        // Regex to match skills based on proficiency
        const proficiencyRegex = new RegExp(`\\(${proficiency}(?:/([^()]+))?\\)`);
        return skillsArray
          .map((skill) => {
            // Convert skill (proficiency/type) -> skill (type)
            return skill.replace(/(\([^)]+)\/([^()]+)\)/, '($2)');
          })
          .filter((skill) => proficiencyRegex.test(skill))
          .map((skill) => skill.replace(proficiencyRegex, '').trim());
      }
      return [];
    };

    setExpertSkill(categorizeSkills('Expert'));
    setIntermediateSkill(categorizeSkills('Intermediate'));
    setBeginnerSkill(categorizeSkills('Beginner'));
  }, [skillInput, addedSkills]);

  const handleRemoveSkill = (skill, prof, type) => {
    const escapedSkill = skill.replace(/([.*+?^=!:${}()|[\]/\\])/g, '\\$1');

    const regex = new RegExp(`${escapedSkill} \\(${prof}\\)( \\(${type}\\))?(,\\s*|$)`, 'g');

    const tempSkillInput = skillInput?.replace(regex, '')?.replace(/,\s*$/, '');

    setSkillInput(tempSkillInput);
    const tempUpdatedSkills = addedSkills?.replace(regex, '')?.replace(/,\s*$/, '');
    const data = {
      skills: tempUpdatedSkills
    };
    editSkill(data)(dispatch);
  };

  const renderSkills = (skills, prof) => {
    return skills?.map((skill, idx) => {
      const isEmpty = /^ *$/.test(skill);

      // const typeRegex = /\(([^)]+)\)/;
      // const match = skill.match(typeRegex);

      const typeRegex = /\((Technical|Functional|Behaviour)\)/;
      const match = skill.match(typeRegex);

      // If a match is found, extract the type (without parentheses); otherwise, set type as an empty string
      const type = match ? match[1] : '';

      const skillName = skill.replace(typeRegex, '').trim(); // Remove everything in parentheses

      return (
        <React.Fragment key={idx}>
          {!isEmpty && (
            <div
              className={s.displaySkillDesc}
              style={{
                backgroundColor:
                  type === 'Technical'
                    ? '#d1f7c4'
                    : type === 'Functional'
                    ? '#eaf2f4'
                    : type === 'Behaviour'
                    ? '#fdd8e6'
                    : 'transparent' // Default color
              }}
            >
              {skillName ? (
                <p>{skillName}</p>
              ) : (
                <Skeleton variant="text" className={s.textLoader} />
              )}

              <Tooltip title="Delete skill">
                <img
                  height={18}
                  src={close}
                  alt="close-icon"
                  onClick={() => handleRemoveSkill(skillName, prof, type)}
                />
              </Tooltip>
            </div>
          )}
        </React.Fragment>
      );
    });
  };

  return (
    <div className={cns(s.skillsWrapper, isMobile ? s.skillResponsive : '')} ref={wrapper}>
      <div className={cns(s.skillsWrapper, isMobile ? s.skillResponsive : '')}>
        <div className={s.heading}>
          <h5>Skills</h5>
          {editFlag ? (
            ''
          ) : (
            <Tooltip title={TOOLTIP_MSSG.ADD}>
              <div className={s.addIcon}>
                <button className={s.addButton} onClick={() => setEditFlag(!editFlag)}>
                  <img src={addIcon} alt="add Icon" />
                </button>
              </div>
            </Tooltip>
          )}
        </div>
        <div className={s.skillDescription}>
          {loading ? (
            <>
              <Skeleton variant="text" className={s.textLoader} />
              <Skeleton variant="text" className={s.textLoader} />
              <Skeleton variant="text" className={s.textLoader} />
              <Skeleton variant="text" className={s.textLoader} />
            </>
          ) : skillInput?.length ? (
            <>
              {expertSkill.length > 0 && (
                <div className={s.skillDiv}>
                  <div className={s.skillType}>Expert: </div>
                  <div className={s.skillNames}> {renderSkills(expertSkill, 'Expert')}</div>
                </div>
              )}

              {intermediateSkill.length > 0 && (
                <div className={s.skillDiv}>
                  <div className={s.skillType}>Intermediate: </div>
                  <div className={s.skillNames}>
                    {renderSkills(intermediateSkill, 'Intermediate')}
                  </div>
                </div>
              )}

              {beginnerSkill.length > 0 && (
                <div className={s.skillDiv}>
                  <div className={s.skillType}>Beginner: </div>
                  <div className={s.skillNames}>{renderSkills(beginnerSkill, 'Beginner')}</div>
                </div>
              )}
            </>
          ) : !editFlag ? (
            <i className="text-secondary">No Skills added.</i>
          ) : (
            ''
          )}

          {editFlag ? (
            <>
              {skillInput?.length ? <hr /> : ''}

              <div className={s.labelWrapper}>
                <div className={s.newSkillCard}>
                  <div className={s.addSkillHeading}>
                    ADD NEW SKILL
                    <div className={s.buttonContainer}>
                      <Tooltip title={TOOLTIP_MSSG.SAVE}>
                        <button onClick={handleSave}>
                          <img src={save} alt="save Icon" />
                        </button>
                      </Tooltip>
                      <Tooltip title={TOOLTIP_MSSG.CLOSE}>
                        <button onClick={handleClose}>
                          <img alt="close Icon" src={close} />
                        </button>
                      </Tooltip>
                    </div>
                  </div>
                  <div className={s.description}>
                    <div>
                      <input
                        autoFocus
                        placeholder="Enter Skill"
                        className={
                          errors &&
                          (!skillData.name.trim() ||
                            skillData.name.length > 40 ||
                            skillData.name.includes(','))
                            ? s.inputError
                            : s.inputName
                        }
                        value={skillData.name}
                        name="skillName"
                        onChange={handleInputChange}
                        type="text"
                      />
                    </div>
                    <div>
                      <select
                        value={skillData.prof}
                        name="proficiency"
                        className={errors && skillData.prof === '' ? s.inputError : s.inputName}
                        onChange={(e) => setSkillData({ ...skillData, prof: e.target.value })}
                      >
                        <option disabled value="">
                          Select Proficiency
                        </option>
                        <option>Beginner</option>
                        <option>Intermediate</option>
                        <option>Expert</option>
                      </select>
                    </div>
                    <div className={s.selectTypeWrapper}>
                      <select
                        value={skillData.type}
                        name="select type"
                        className={errors && skillData.type === '' ? s.inputError : s.inputName}
                        onChange={(e) => setSkillData({ ...skillData, type: e.target.value })}
                      >
                        <option disabled value="">
                          Select Type
                        </option>
                        <option>Technical</option>
                        <option>Behaviour</option>
                        <option>Functional</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            ''
          )}
        </div>
      </div>
    </div>
  );
};
export default Skill;
